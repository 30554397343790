import { css } from 'app/styled-system/css';

export const getFavoriteButtonCX = (isBiggerListingCard: boolean) =>
  css({
    width: isBiggerListingCard ? '40px' : '50px',
    height: isBiggerListingCard ? '40px' : '50px',
    padding: '0',
    marginRight: isBiggerListingCard ? '10px' : '0',
    _hover: {
      cursor: 'pointer',
    },
  });

export const getHeartIconAndAnimationCX = (pulse: number) =>
  css({
    animation: pulse === 1 ? 'pulsing' : '',
  });
