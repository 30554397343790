import type { ElementType, ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';
import './style.scss';

interface CenterProps {
  children?: ReactNode;
  htmlTag?: ElementType;
  className?: string;
}

const Center: React.FC<CenterProps> = ({ children = null, htmlTag: CustomTag = 'div', className = null }) => {
  return <CustomTag className={cx('Center', className)}>{children}</CustomTag>;
};

export default Center;
